<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="importextgong">
        <div class="box" style="margin-top:0;">
            <p class="daoru_daochu_tit">
                <span @click="dianji_fanhui">返回</span>
            </p>
            <ul class="daoru_daochu_con_tit">
                <li v-for="(i,index) in daoru_daochu_list" :key="index" @click="daoru_or_daochu=index" :style="daoru_or_daochu==index?'border:0.01rem solid #9a86db;background:#9a86db;color:#fff;':''">{{i}}</li>
            </ul>
            <div class="daoru_daochu_con">
                <div class="daoru_daochu_con_zhanshi">
                    <div>
                        <p class="con_tit">{{daoru_or_daochu==0?'1、下载供应商录入单，填写供应商信息':'1、按部门导出客户信息'}}</p>
                        <img class="daoru_daochu_tu" src="../../../assets/mobanxiazai.png" alt="">
                        <span class="con_btn" @click="daoru_or_daochu==0?moban_xiazai():shuju_daochu()">{{daoru_or_daochu==0?"下载模板":'导出'}}</span>
                    </div>
                    <div>
                        <p class="con_tit">{{daoru_or_daochu==0?'2、上传客户录入单文件':'2、上传修改好的客户录入单文件'}}</p>
                        <img class="daoru_daochu_tu" src="../../../assets/daoru.png" alt="">
                        <p class="con_tishi">仅支持xls、xlsx格式文件</p>
                        <p class="con_tishi" style="color:red;">单次导入条数不超过10000条</p>
                        <el-upload
                            class="upload-demo"
                            ref="upload"
                            action=''
                            :file-list="fileList"
                            accept=".xls,.xlsx"
                            :multiple="false"
                            :auto-upload="false">
                            <el-button style="background: #9a86db;border:none;margin-left:0.15rem;margin-right:0.3rem;" slot="trigger" size="small" type="primary">选取文件</el-button>
                        </el-upload>
                        <span class="con_btn" @click="uploadFile">上传文件</span>
                    </div>
                </div>
            </div>
        </div>
        <!--   -->
        <div class="importexport_beijing" v-if="zhuangtai">
            <div class="importexport_beijing_box" @click.stop>
                <p>
                    <i class="el-icon-warning"></i>成功导入{{chenggong}}条供应商信息，<span style="cursor:pointer;color:red;" @click="dianji_jinru_shibai">失败{{shibai}}条</span>
                </p>
                <p>
                    {{cuowu_wenjian.length!=0?cuowu_wenjian+'，文件格式不正确':''}}
                </p>
                <p>
                    <span @click="zhuangtai=false">我知道了</span>
                </p>
            </div>
        </div>
        <jindutiao ref="jindu_tiao"></jindutiao>
    </div>
</template>

<script>
import { query_user_info, query_ent_dept_list_aim, query_all_module_read_power_list } from '../../../api/api.js'
import axios from '../../../api/ajax'
import { showLoading, hideLoading } from '../../../Js/loading'
import jindutiao from '../../../components/jindutiao/jindutiao'
export default {
  name: 'importextgong',
  data () {
    return {
      // ,'导出/修改客户'
      daoru_daochu_list: ['批量导入供应商'],
      daoru_or_daochu: 0,
      fileList: [],
      chenggong: '0',
      shibai: '0',
      cuowu_wenjian: '',
      zhuangtai: false,
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
      quanbu_kejian: false,
      zhuguan: false,
      dept_id: ''
    }
  },
  components: { jindutiao },
  mounted () {
    this.jichu2()// 加载他的部门id
  },
  created () {
    this.jichu()// 是否是看全部   或者主管 或者 子管理员
  },
  methods: {
    jichu () {
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
          }
        } else if (res.data.code == 500) {}
      })
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.zhuguan = true
          } else {
            this.zhuguan = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    jichu2 () {
      query_ent_dept_list_aim({
        data: {
          ent_id: this.$ent_id(),
          user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
        }
      }).then(ras => {
        console.log(ras)
        if (ras.data.code == 10168) {
          const date = JSON.parse(ras.data.body.data)
          this.dept_id = (this.quanbu_kejian || this.zhuguan) ? null : date.map(item => item.dept_id).join(',')
          // this.bumen_tree=date
        } else if (ras.data.code == 10169) {}
      })
    },
    dianji_fanhui () {
      window.history.go(-1)
    },
    //   下载多个文件
    multiDownLoad (val) {
      for (let i = 0; i < val.length; i++) {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = val[i]
        document.body.appendChild(iframe)
        setTimeout(res => {
          iframe.remove()
        }, 5 * 60 * 1000
        )
      }
    },
    // 模板下载
    moban_xiazai () {
      const data = ['https://www.mlcbr.com/template/CBR-供应商导入模板.xlsx']
      this.multiDownLoad(data)
      // CBR-客户录入单导入模板.xlsx
    },
    // 上传文件
    uploadFile () {
      console.log(this.$refs.upload.uploadFiles)
      const date = this.$refs.upload.uploadFiles
      const list = []
      const shibai = []
      const fasong = []
      for (let i = 0; i < date.length; i++) {
        list.push(date[i].raw)
      }
      console.log(list)
      for (let i = 0; i < list.length; i++) {
        if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
          shibai.push(list[i])
        } else {
          showLoading()
          const fileObj = list[i]
          const form = new FormData()// FormData 对象
          form.append('files', fileObj)// 文件对象  'upload'是后台接收的参数名
          form.append('ent_id', this.$ent_id())
          sessionStorage.setItem('pc_mlbb_shangchuan_chenggong', fileObj.name)
          // console.log(fileObj)
          // sessionStorage.setItem("pc_mlbb_shangchuan_chenggong", fileObj.name);
          axios({
            method: 'post',
            url: '/import_supply_ai',
            data: form,
            processData: false,
            contentType: false,
            timeout: 1200000,
            onUploadProgress: progressEvent => {
              var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              // this.text = complete + '%'
              console.log(complete + '%')
              // this.$refs.jindu_tiao.huoqu(true,complete)
            }
          }).then(res => {
            console.log(res)
            hideLoading()
            // this.$refs.jindu_tiao.huoqu(false,0)
            if (res.data.code == 200) {
              const datea = {
                name: '供应商录入单',
                cuowu: true
              }
              console.log(datea)

              sessionStorage.setItem('pc_mlbb_bumenrzhi', JSON.stringify(datea))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '供应商录入单')
              // this.chenggong=res.data.body.success_size
              // this.shibai=res.data.body.fail_size
              // this.zhuangtai=true
              this.$router.push('/feedbackpage')
            } else if (res.data.code == 500) {
              this.$message({
                message: '导入失败，请重新导入',
                type: 'error'
              })
            } else if (res.data.code == 11800) {
              this.$message({
                message: '导入文件的sheet页名不对',
                type: 'error'
              })
            } else if (res.data.code == 11900) {
              this.$message({
                message: '导入文件的模板格式不对',
                type: 'error'
              })
            }
          })
          // $.ajax({
          //     url:'https://www.mlcbr.com/hwb_client/V1.0.0/import_customer_ai',
          //     // url:'http://192.168.3.5:8080/hwb_client/V1.0.0/import_customer_ai',
          //     type: 'post',
          //     data: form,
          //     processData: false,
          //     contentType: false,
          //     onUploadProgress: progressEvent => {
          //         var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
          //         // this.text = complete + '%'
          //         console.log(complete + '%')
          //     }
          // }).then(res=>{
          //     console.log(res)
          //     hideLoading();
          //     // sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju", JSON.stringify(res.body));
          //     if(res.code==10177){
          //         this.$message({
          //             message: '导入成功',
          //             type: 'success'
          //         });
          //         this.chenggong=res.data.body.data
          //         this.zhuangtai=true
          //         // this.$router.push('/feedbackpage')
          //     }else if(res.code==10178){
          //         this.$message({
          //             message: '导入失败，请重新导入',
          //             type: 'error'
          //         });
          //     }
          // })
        }
      }
      let wenjian_name = ''
      for (let i = 0; i < shibai.length; i++) {
        if (wenjian_name.length == 0) {
          wenjian_name = shibai[i].name
        } else {
          wenjian_name = wenjian_name + ',' + shibai[i].name
        }
      }
      this.cuowu_wenjian = wenjian_name
      if (this.cuowu_wenjian.length != 0) {
        this.zhuangtai = true
      }
    },

    // 数据导出
    shuju_daochu () {
      const date = this.url + 'export_customr_sea?ent_id=' + this.$ent_id() + '&dept_id=' + this.dept_id == null ? '0' : this.dept_id
      this.$func.multiDownLoad([date])
    },
    dianji_jinru_shibai () {
      // let date={
      //     name:'',
      //     cuowu:true
      // }
      // if(this.text_name=='部门'){
      //     date.name='部门'
      // }else if(this.text_name=='科目'){
      //     date.name='科目'
      // }else if(this.text_name=='产品'){
      //     date.name='产品'
      // }
      // sessionStorage.setItem("pc_mlbb_bumenrzhi", JSON.stringify(date));
      const date = {
        name: '供应商录入单',
        cuowu: true
      }
      console.log(date)

      sessionStorage.setItem('pc_mlbb_bumenrzhi', JSON.stringify(date))
      this.$router.push('/calendara')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../../style/salesman.scss'
</style>
